// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  section.section-popular {
    .popular-flexbox {
      .flexbox-body {
        .products-flexbox.grid-list {
          .product-service {
            align-items: center;

            header {
              .service-image-wrapper {
                width: 80%;
                padding-top: 80%;
              }
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  nav.section-header {
    .flexbox-dropdown-wrapper {
      .flexbox-dropdown {
        margin-top: 0;
      }
    }

    .header-flexbox {
      .nav-button {
        display: flex;
        margin-right: -10px;

        .button-bar {
          background: @color1;
        }
      }

      .flexbox-logo {
        p:nth-child(1) {
          font-size: 24px;
        }

        p:nth-child(2) {
          font-size: 11px;
        }
      }

      .flexbox-top {
        ul {
          li {
            margin: 0 10px;

            .price-badge.hurt {
              display: none;
            }

            a {
              flex-direction: column;
              align-items: center;
              text-align: center;
              font-size: 12px;

              svg {
                margin-right: 0;
              }

              p {
                font-size: 9px;
              }

              &.active {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }

      .flexbox-service {
        padding: 10px 0 10px 0;
      }

      .flexbox-nav-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        background: #fff;
        z-index: 555;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        .nav-button-close {
          display: block;
        }

        .flexbox-nav-inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 100%;
          padding: 50px;
          margin-right: 0;

          .flexbox-nav {
            flex-direction: column;

            li {
              a {
                padding: 10px 15px;
                font-size: 20px;
              }

              &.mobile {
                display: inline-block;
              }

              &.contact {
                margin-top: 10px;
                margin-left: 0;
                padding: 10px;

                .btn-small {
                  font-size: 16px;
                  padding: 5px;
                }

                .phone {
                  font-size: 13px;
                }
              }
            }
          }
        }

        &.active {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .products-flexbox {
    .product-service {
      width: 31%;

      .btn-more {
        padding: 12px;
      }

      .name {
        font-size: 12.5px;
      }
    }
  }
}

// Medium devices (tablets, less than 890px)
@media (max-width: 890px) {
  section.section-popular {
    .popular-flexbox {
      .flexbox-body {
        padding-top: 60px;

        .body-advantages {
          margin-top: 20px;

          .advantage-service {
            padding-top: 20px;
          }
        }

        .heading {
          margin-bottom: 20px;
          font-size: 14px;
        }

        .select-wrapper {
          select {
            padding: 5px 50px 5px 10px;
          }
        }

        .body-product {
          flex-direction: column;
          align-items: center;

          .product-gallery,
          .product-info {
            width: 70%;
          }

          .product-info {
            padding-top: 50px;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, less than 799px)
@media (max-width: 800px) {
  .products-flexbox {
    .product-service {
      align-items: center;
      width: 48%;

      .badge-wrapper {
        left: 23px;
      }

      .service-quantity {
        .quantity-inner-input-wrapper {
          margin: 0 5px;
        }
      }

      .footer-quantity {
        &.active {
          width: 170px;
        }
      }
    }

    &.wyszukiwanie {
      .product-service {
        width: 48%;
      }
    }
  }

  section.section-popular {
    padding: 0 0 70px 0;

    .popular-flexbox {
      padding-top: 30px;

      .flexbox-sidebar {
        .category-nav-wrapper {
          .category-nav {
            padding-top: 0;
          }
        }

        .shadow-heading {
          display: none;
        }
      }

      .flexbox-body {
        padding-top: 0;
      }
    }
  }

  section.kontakt-section-content {
    padding: 50px 0;

    .service-heading {
      text-align: center;

      h3 {
        font-size: 24px;
      }
    }

    .service-content {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }

    .content-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-address,
      .flexbox-service,
      .flexbox-heading,
      .iframe-wrapper {
        width: 100%;
        text-align: center;
      }

      .flexbox-service {
        margin-top: 20px;
      }

      .iframe-wrapper {
        margin-top: 20px;
        height: 250px;
      }
    }
  }
}

@media (max-width: 600px) {
  nav.section-header {
    .header-search-engine {
      input {
        padding: 10px;
      }

      .btn-search {
        padding: 0 15px;
      }
    }

    .header-flexbox {
      .flexbox-top {
        ul {
          li {
            margin-right: 15px;
          }
        }
      }

      .flexbox-nav-wrapper {
        .flexbox-nav-inner {
          .flexbox-nav {
            li {
              a {
                padding: 10px 0;
              }
            }
          }
        }
      }
    }
  }

  section.section-welcome {
    .welcome-inner {
      h2 {
        font-size: 28px;
      }

      h3 {
        font-size: 16px;
        margin-top: 0;
      }
    }
  }

  section.section-popular {
    .popular-flexbox {
      padding-top: 0;
      flex-direction: column;

      .nav-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
      }

      .flexbox-sidebar {
        .heading {
          display: none;
        }
      }

      .flexbox-body {
        padding-top: 0;
        margin-top: 0;

        .heading {
          font-size: 24px;
        }

        .products-flexbox {
          .product-service {
            header {
              width: 80%;
            }
          }
        }

        .body-heading {
          flex-direction: column;

          .inner-wrapper {
            padding-top: 20px;
            justify-content: center;

            .heading-grid {
              display: none;
            }
          }
        }

        .select-wrapper {
          select {
            padding: 5px 25px 5px 5px;
          }
        }

        .body-advantages {
          margin-top: 20px;

          .advantage-service {
            min-width: 48%;
          }
        }

        .body-cta {
          .cta-inner {
            p {
              font-size: 16px;

              strong {
                font-size: 20px;
              }
            }
          }
        }

        .body-package-wrapper {
          .body-package {
            display: flex;
            flex-direction: column;
            align-items: center;

            .package-image {
              width: 35.5%;
            }

            .package-info {
              width: 70%;
              text-align: center;
              padding-top: 20px;

              .price {
                justify-content: center;
              }

              .date {
                font-size: 20px;
              }
            }

            .package-gift {
              width: 45%;
              padding-top: 20px;

              .heading {
                font-size: 13px;
              }
            }
          }
        }

        .body-product {
          .product-info {
            padding-top: 20px;

            h2 {
              font-size: 22px;
            }

            .price {
              .quantity-wrapper {
                .wrapper-inner {
                  display: flex;
                  justify-content: space-between;

                  .quantity-inner-input {
                    width: 115px;
                  }
                }
              }
            }
          }
        }

        .body-more {
          .btn {
            padding: 15px 0;
          }
        }

        .btn-fixed {
          &:nth-of-type(1) {
            bottom: 80px;
            right: 190px;
          }

          &:nth-of-type(2) {
            right: 198px;
          }
        }
      }

      .flexbox-sidebar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .category-nav-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          overflow-y: scroll;
          background: #fff;
          z-index: 555;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;

          .nav-button-close {
            display: block;
          }

          .category-nav-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            min-height: 100%;
            padding: 50px;

            .category-nav {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;

              li {
                a {
                  font-size: 18px;
                  padding: 8px 0;
                }

                ul {
                  li {
                    a {
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }

          &.active {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
          }
        }
      }

      .flexbox-body {
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  section.kategoria-section-heading {
    height: 100px;

    img {
      height: 100%;
    }

    .heading-inner {
      h1 {
        font-size: 40px;
      }

      p {
        font-size: 22px;
      }
    }
  }

  section.static-page-section-content {
    padding: 50px 0;
  }

  section.section-footer {
    .footer-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-service {
        margin-top: 30px;

        ul {
          text-align: center;
        }
      }
    }

    .footer-credits {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {
  .btn {
    padding: 8px 25px;
  }

  .products-flexbox {
    .product-service {
      width: 100%;

      .service-image-wrapper {
        .service-image {
          .order-more {
            right: 27%;
          }
        }
      }
    }

    &.wyszukiwanie {
      .product-service {
        width: 100%;
      }
    }
  }

  nav.section-header {
    .header-flexbox {
      .flexbox-top {
        ul {
          li {
            a {
              font-size: 10px;
            }
          }
        }
      }
    }

    .flexbox-dropdown-wrapper {
      .flexbox-dropdown {
        left: 0;
        width: 330px;

        .dropdown-list {
          li {
            p {
              font-size: 11px;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  section.section-welcome {
    height: 100px;
  }

  section.kategoria-section-heading {
    height: 80px;

    .heading-inner {
      h1 {
        font-size: 26px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .breadcrumb-wrapper {
    .breadcrumb {
      .breadcrumb-item {
        font-size: 10px;
      }
    }
  }

  section.section-popular {
    .popular-flexbox {
      .flexbox-body {
        .buttons {
          right: 0;
          left: 0;
          align-items: center;
        }

        .no-products {
          font-size: 14px;
        }

        .products-flexbox {
          &.grid-list {
            .product-service {
              flex-direction: column;

              header {
                .service-image-wrapper {
                  width: 100%;
                  padding-top: 100%;
                }
              }

              .name {
                width: 100%;
              }
            }
          }
        }

        .body-heading {
          margin: 0 0 40px 0;
        }

        .body-cta {
          height: 100px;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}
