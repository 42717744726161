@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v21/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Hw3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v21/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v21/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Hw3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v21/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v21/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC73w3aX8.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body .container-fluid {
  max-width: 1260px;
}
body .btn {
  padding: 8px 30px;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.4s;
}
body .btn.btn-default {
  background: #1d5ab5;
  color: #fff;
}
body .btn.btn-default:hover {
  background: #164489;
}
body .btn.btn-small {
  font-size: 11px;
  padding: 6px 20px;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
.select-wrapper {
  position: relative;
}
.select-wrapper select {
  cursor: pointer;
  padding: 5px 100px 5px 10px;
  border: none;
  border-bottom: 2px solid #bbb;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 13px;
  appearance: none;
  outline: none;
  display: block;
  color: #666;
  width: 100%;
}
.select-wrapper:after {
  cursor: pointer;
  content: "";
  height: 10px;
  width: 10px;
  background: url("../img/pictures/down-chevron.svg") center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.products-flexbox .product-service {
  margin-top: 30px;
  width: 22.5%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.products-flexbox .product-service .badge-wrapper {
  position: absolute;
  z-index: 111;
  top: 0;
  left: 0;
}
.products-flexbox .product-service .badge-wrapper .badge {
  width: 70px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 9px;
  border-radius: 2px;
}
.products-flexbox .product-service .badge-wrapper .badge.promotion {
  background: orangered;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.new {
  background: seagreen;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.bestseller {
  background: lightseagreen;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge:not(:first-child) {
  margin-top: 5px;
}
.products-flexbox .product-service .service-image-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 7px;
  display: block;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  background: #fff;
}
.products-flexbox .product-service .service-image-wrapper .service-image {
  text-align: center;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 7px;
}
.products-flexbox .product-service .service-image-wrapper .service-image .order-more {
  position: absolute;
  bottom: 10px;
  right: 25%;
  width: 90px;
  padding: 3px;
  border-radius: 1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  background-color: #1d5ab5;
  display: none;
  box-shadow: 0 0 0 rgba(29, 90, 181, 0.4);
  animation: pulse 2s infinite ease-in-out;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(29, 90, 181, 0.4);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(29, 90, 181, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(29, 90, 181, 0);
  }
}
.products-flexbox .product-service .service-image-wrapper .service-image img {
  width: 100%;
}
.products-flexbox .product-service .service-image-wrapper.active {
  box-shadow: 0px 10px 15px 0px rgba(211, 14, 14, 0.3);
}
.products-flexbox .product-service .service-quantity {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.products-flexbox .product-service .service-quantity p {
  font-weight: 700;
  font-size: 11px;
}
.products-flexbox .product-service .service-quantity input {
  width: 70px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #1d5ab5;
  font-size: 14px;
  outline: none;
  padding: 0 20px 0 10px;
  transition: all 0.4s;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
}
.products-flexbox .product-service .service-quantity input.active {
  border: 1px solid #d30e0e;
}
.products-flexbox .product-service .service-quantity input:hover .button-quantity-wrapper {
  opacity: 1;
}
.products-flexbox .product-service .service-quantity button {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: none;
  background: #1d5ab5;
  color: #fff;
  cursor: pointer;
  transition: background 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-flexbox .product-service .service-quantity button img {
  height: 11px;
}
.products-flexbox .product-service .service-quantity button:hover {
  background: #164489;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper {
  position: relative;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  opacity: 0;
  right: 3px;
  width: 17px;
  height: 22px;
  top: 1.5px;
  background: #f6f6f6;
  cursor: none;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number.increment,
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number.decrement {
  position: absolute;
  right: 0;
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 0;
  transition: none;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number.increment img,
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number.decrement img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7px;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number.increment {
  top: 0;
  background: #ddd;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number.increment.active {
  background: #f6f6f6;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number.decrement {
  bottom: 0;
  background: #ddd;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number.decrement.active {
  background: #f6f6f6;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number:active,
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper .quantity-button-number:focus {
  outline: none;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper.active {
  opacity: 1;
}
.products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper .button-quantity-wrapper:hover {
  background: #f6f6f6;
  opacity: 1;
}
.products-flexbox .product-service .name {
  margin-top: 20px;
  color: #333;
  font-weight: 500;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
}
.products-flexbox .product-service .price {
  margin-top: 10px;
  color: #1d5ab5;
  font-size: 23px;
  font-weight: 600;
}
.products-flexbox .product-service .price small {
  font-size: 14px;
  font-weight: 400;
  color: #444;
  margin-right: 5px;
  position: relative;
  display: none;
}
.products-flexbox .product-service .price small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -3px;
  width: 115%;
  height: 1px;
  background: #000;
  transform: rotate(-10deg);
}
.products-flexbox .product-service .price span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
.products-flexbox .product-service .price-small {
  font-size: 12px;
  color: #999;
}
.products-flexbox .product-service .price-small small {
  font-weight: 400;
  color: #999;
  margin-right: 5px;
  position: relative;
  display: none;
}
.products-flexbox .product-service .price-small small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -1px;
  width: 110%;
  height: 1px;
  background: #222;
  transform: rotate(-10deg);
}
.products-flexbox .product-service .type {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
.products-flexbox .product-service .type strong {
  color: #1d5ab5;
}
.products-flexbox .product-service .footer-quantity {
  margin-top: 15px;
  padding: 5px 8px;
  background: #f3f3f3;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}
.products-flexbox .product-service .footer-quantity.active {
  background: #fff;
}
.products-flexbox .product-service .footer-quantity p {
  font-size: 11px;
  color: #333;
}
.products-flexbox .product-service .footer-quantity p:nth-child(2) {
  font-weight: 700;
  color: #1d5ab5;
}
.products-flexbox .product-service .btn-more {
  margin-top: 17px;
  background: #999;
  width: 100%;
  padding: 3px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #fff;
  border-radius: 4px;
  display: block;
  border: none;
  transition: all 0.4s;
}
.products-flexbox .product-service:hover .service-image-wrapper {
  transform: translateY(-4px);
  box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.1);
}
.products-flexbox .product-service:hover .service-image-wrapper.active {
  box-shadow: 0px 20px 15px 0px rgba(211, 14, 14, 0.3);
}
.products-flexbox .product-service:hover .name {
  color: #1d5ab5;
}
.products-flexbox .product-service:hover .btn-more {
  background: #1d5ab5;
}
.products-flexbox .product-service.promotion .price {
  color: #ff4500;
}
.products-flexbox .product-service.promotion .price small {
  display: inline-block;
}
.products-flexbox .product-service.promotion .price-small small {
  display: inline-block;
}
.products-flexbox .product-service.service-hidden {
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
}
.products-flexbox.grid-list .product-service {
  width: 100% !important;
  flex-direction: row;
}
.products-flexbox.grid-list .product-service .service-image-wrapper {
  width: 20%;
  padding-top: 20%;
  position: relative;
  border-radius: 7px;
  display: block;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  background: #fff;
}
.products-flexbox.grid-list .product-service .service-image-wrapper .service-image {
  text-align: center;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 7px;
}
.products-flexbox.grid-list .product-service .service-image-wrapper .service-image img {
  width: 100%;
}
.products-flexbox.grid-list .product-service .service-image-wrapper.active {
  box-shadow: 0px 10px 15px 0px rgba(211, 14, 14, 0.3);
}
.products-flexbox.grid-list .product-service .service-quantity {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.products-flexbox.grid-list .product-service .service-quantity p {
  font-weight: 700;
  font-size: 11px;
}
.products-flexbox.grid-list .product-service .service-quantity input {
  width: 70px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #1d5ab5;
  font-size: 14px;
  outline: none;
  padding: 0;
  transition: all 0.4s;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
}
.products-flexbox.grid-list .product-service .service-quantity input.active {
  border: 1px solid #d30e0e;
}
.products-flexbox.grid-list .product-service .service-quantity button {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: none;
  background: #1d5ab5;
  color: #fff;
  cursor: pointer;
  transition: background 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-flexbox.grid-list .product-service .service-quantity button img {
  height: 11px;
}
.products-flexbox.grid-list .product-service .service-quantity button:hover {
  background: #164489;
}
.products-flexbox.grid-list .product-service .name {
  margin-top: 20px;
  color: #333;
  font-weight: 500;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
}
.products-flexbox.grid-list .product-service .price {
  margin-top: 10px;
  color: #1d5ab5;
  font-size: 23px;
  font-weight: 600;
}
.products-flexbox.grid-list .product-service .price span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
.products-flexbox.grid-list .product-service .price-small {
  font-size: 12px;
  color: #999;
}
.products-flexbox.grid-list .product-service .type {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
.products-flexbox.grid-list .product-service .type strong {
  color: #1d5ab5;
}
.products-flexbox.grid-list .product-service .footer-quantity {
  margin-top: 15px;
  padding: 5px 8px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}
.products-flexbox.grid-list .product-service .footer-quantity p {
  font-size: 11px;
  color: #333;
}
.products-flexbox.grid-list .product-service .footer-quantity p:nth-child(2) {
  font-weight: 700;
  color: #1d5ab5;
}
.products-flexbox.grid-list .product-service .btn-more {
  margin-top: 17px;
  background: #999;
  width: 100%;
  padding: 3px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #fff;
  border-radius: 4px;
  display: block;
  border: none;
  transition: all 0.4s;
}
.products-flexbox.grid-list .product-service:hover .service-image-wrapper {
  transform: translateY(-4px);
  box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.1);
}
.products-flexbox.grid-list .product-service:hover .service-image-wrapper.active {
  box-shadow: 0px 20px 15px 0px rgba(211, 14, 14, 0.3);
}
.products-flexbox.grid-list .product-service:hover .name {
  color: #1d5ab5;
}
.products-flexbox.grid-list .product-service:hover .btn-more {
  background: #1d5ab5;
}
.products-flexbox.grid-list .product-service.service-hidden {
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
}
.products-flexbox.wyszukiwanie .product-service {
  width: 18%;
}
.breadcrumb-wrapper .breadcrumb {
  border-radius: 0;
  padding: 10px 20px;
  background: #f3f3f3;
  margin-bottom: 0;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item {
  font-size: 12px;
  font-weight: 500;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a {
  color: #1d5ab5;
}
.pagination-nav {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pagination-nav li a {
  display: block;
  padding: 10px;
  color: #999;
  text-transform: uppercase;
}
.pagination-nav li.active a {
  font-weight: 700;
  color: #1d5ab5;
}
section.section-alert {
  background: #c12525;
  padding: 5px 0;
}
section.section-alert p {
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 13px;
}
nav.section-header.margin-bottom {
  margin-bottom: 70px;
}
nav.section-header .header-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
nav.section-header .header-flexbox .flexbox-logo {
  border-top: 2px solid #1d5ab5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
nav.section-header .header-flexbox .flexbox-logo p {
  color: #1d5ab5;
  transform: translateY(-5px);
}
nav.section-header .header-flexbox .flexbox-logo p:nth-child(1) {
  font-size: 31px;
  font-weight: 900;
  font-style: italic;
  letter-spacing: 5px;
  text-transform: uppercase;
}
nav.section-header .header-flexbox .flexbox-logo p:nth-child(2) {
  font-size: 15.3px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 1.8px;
  padding-left: 1px;
}
nav.section-header .header-flexbox .flexbox-logo p:nth-child(2) strong {
  font-weight: 700;
}
nav.section-header .header-flexbox .flexbox-service {
  padding: 25px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-top {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-top ul {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-top ul li {
  margin-right: 40px;
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-top ul li .price-badge {
  margin-right: 10px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
nav.section-header .header-flexbox .flexbox-top ul li .price-badge.hurt {
  background: olivedrab;
}
nav.section-header .header-flexbox .flexbox-top ul li .price-badge.detal {
  background: teal;
}
nav.section-header .header-flexbox .flexbox-top ul li a {
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: all 0.4s;
  color: #333;
  font-weight: 500;
  cursor: pointer;
}
nav.section-header .header-flexbox .flexbox-top ul li a svg {
  height: 20px;
  margin-right: 10px;
  fill: #333;
  transition: fill 0.4s;
}
nav.section-header .header-flexbox .flexbox-top ul li a:hover {
  opacity: 0.6;
}
nav.section-header .header-flexbox .flexbox-top ul li a.active {
  color: #1d5ab5;
  font-weight: 600;
}
nav.section-header .header-flexbox .flexbox-top ul li a.active span {
  font-weight: 400;
  color: #333;
  font-size: 13px;
  padding-left: 7px;
}
nav.section-header .header-flexbox .flexbox-top ul li a.active svg {
  fill: #164489;
}
nav.section-header .header-flexbox .flexbox-top ul li a.active:hover {
  color: #164489;
  opacity: 0.7;
}
nav.section-header .header-flexbox .flexbox-top ul li a.active:hover svg {
  fill: #0f2e5d;
}
nav.section-header .header-flexbox .flexbox-top ul li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #555;
  font-weight: 500;
  font-size: 15px;
  padding: 35px 14px;
  display: block;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact {
  margin-left: 20px;
  padding: 7px;
  border-radius: 8px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact .btn-small {
  font-size: 12px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 6px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact .phone {
  padding: 0 13px 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact .phone img {
  margin-right: 7px;
  height: 15px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact .phone:hover {
  color: #1d5ab5;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
nav.section-header .flexbox-dropdown-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown {
  margin-top: -66px;
  position: absolute;
  z-index: 111;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  width: 460px;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-empty {
  font-size: 14px;
  padding: 10px 50px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  color: #333;
  font-weight: 500;
  text-align: center;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .heading {
  text-align: right;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-list {
  margin-top: 20px;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-list li {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-list li p {
  width: 100%;
  margin-right: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-list li span {
  font-weight: 700;
  padding: 3px 8px;
  display: block;
  background: #efefef;
  border-radius: 3px;
  white-space: nowrap;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-list li:not(:first-child) {
  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px solid #eee;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-summary {
  padding: 15px 0 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-summary p {
  font-size: 16px;
  font-weight: 300;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-summary p strong {
  color: #1d5ab5;
  font-weight: 700;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-summary p:nth-child(1) {
  margin-right: 10px;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-more {
  text-align: right;
  margin-top: 20px;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-more a {
  font-weight: 700;
  color: #1d5ab5;
  font-size: 13px;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-buttons .btn {
  width: 100%;
}
nav.section-header .flexbox-dropdown-wrapper.opacity-hidden {
  visibility: hidden;
  opacity: 0;
}
nav.section-header .header-search-engine-wrapper.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 222;
}
nav.section-header .header-search-engine {
  padding: 15px;
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
  transition: all 0.4s;
}
nav.section-header .header-search-engine.active {
  background: #ddd;
}
nav.section-header .header-search-engine input {
  width: 100%;
  margin-right: 15px;
  border-radius: 12px;
  height: 40px;
  padding: 10px 15px;
  border: 1px solid #fff;
  transition: all 0.4s;
}
nav.section-header .header-search-engine input:focus {
  outline: none;
  border: 1px solid #1d5ab5;
}
nav.section-header .header-search-engine input.active {
  height: 48px;
  border: 1px solid #80abeb;
}
nav.section-header .header-search-engine input.active:focus {
  border: 1px solid #1d5ab5;
}
nav.section-header .header-search-engine input::placeholder {
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  color: #999;
}
nav.section-header .header-search-engine .btn-search {
  padding: 0px 30px;
  background: #1d5ab5;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background 0.4s;
}
nav.section-header .header-search-engine .btn-search img {
  height: 14px;
  margin-right: 10px;
}
nav.section-header .header-search-engine .btn-search:hover {
  background: #164489;
}
section.section-welcome {
  position: relative;
  height: 220px;
}
section.section-welcome img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.section-welcome .welcome-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
section.section-welcome .welcome-inner h2 {
  font-size: 48px;
  color: #fff;
}
section.section-welcome .welcome-inner h3 {
  margin-top: 10px;
  font-weight: 300;
  font-size: 24px;
  color: #fff;
}
section.section-popular {
  padding: 40px 0 70px;
}
section.section-popular .popular-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.section-popular .popular-flexbox .nav-button {
  outline: none;
  padding: 12px 30px;
  font-size: 14px;
  transition: all 0.3s;
  display: none;
  position: relative;
  background: #164489;
  border: none;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
}
section.section-popular .popular-flexbox .nav-button img {
  height: 22px;
  margin-right: 10px;
}
section.section-popular .popular-flexbox .nav-button:hover {
  background: #1d5ab5;
}
section.section-popular .popular-flexbox .flexbox-sidebar {
  width: 23%;
  position: relative;
}
section.section-popular .popular-flexbox .flexbox-sidebar .shadow-heading {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-45%, 0);
  font-weight: 900;
  color: #efefef;
  font-size: 60px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .heading {
  font-size: 16px;
  letter-spacing: 2px;
  color: #777;
  position: relative;
  margin-bottom: 10px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper {
  position: relative;
  height: 100%;
  margin-top: 40px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: -10px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:before,
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:after {
  content: none;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav {
  padding-top: 18px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav li {
  list-style: none;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav li a {
  color: #333;
  padding: 5px 0;
  font-size: 20px;
  transition: all 0.4s;
  display: block;
  text-transform: lowercase;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav li a:first-letter {
  text-transform: uppercase;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav li a:hover {
  color: #1d5ab5;
  padding-left: 5px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav li ul {
  padding-left: 20px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav li ul li a {
  padding: 5px 0;
  display: block;
  font-size: 15px;
  color: #777;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav li.active > a {
  font-weight: 700;
  color: #1d5ab5;
  padding-left: 0;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav li:last-child {
  margin-bottom: 0;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .small-heading {
  margin-top: 50px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav ul li .checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav ul li .checkbox-wrapper .radio-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000;
  letter-spacing: 0.8px;
  font-weight: 300;
  text-align: left;
  transition: all 0.3s;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav ul li .checkbox-wrapper .radio-box:before {
  content: "";
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: block;
  background: url("../img/icons/checkbox.svg") no-repeat;
  margin-right: 10px;
  transition: all 0.3s;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav ul li .checkbox-wrapper input {
  visibility: hidden;
  position: absolute;
  width: auto !important;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav ul li .checkbox-wrapper > input:checked + .radio-box {
  color: #1d5ab5;
  font-weight: 500;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav ul li .checkbox-wrapper > input:checked + .radio-box:before {
  background: url("../img/icons/checkbox-checked.svg") no-repeat;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .inputs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .inputs-wrapper input {
  width: 100%;
  height: 30px;
  background: #e0e1dc;
  border: none;
  text-align: center;
  padding: 0 5px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .inputs-wrapper input:placeholder {
  color: #414141;
  font-size: 13px;
  font-weight: 300;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .inputs-wrapper span {
  padding: 0 20px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .inputs-wrapper input[type="number"]::-webkit-inner-spin-button,
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .inputs-wrapper input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .inputs-wrapper input[type="number"] {
  -moz-appearance: textfield;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  position: relative;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider .slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider .slider-wrapper input[type="number"] {
  border: none;
  margin: 0 10px;
  outline: none;
  text-align: center;
  font-size: 14px;
  height: 30px;
  border-bottom: 1px solid #1d5ab5;
  width: 100%;
  max-width: 65px;
  -moz-appearance: textfield;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider .slider-wrapper input[type="number"]::-webkit-outer-spin-button,
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider .slider-wrapper input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider .slider-wrapper input[type="number"]:invalid,
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider .slider-wrapper input[type="number"]:out-of-range {
  border: 1px solid #ff6347;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider .slider-wrapper span {
  display: block;
  min-width: 12px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .range-slider .slider-wrapper p {
  font-size: 13px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .filter-nav .buttons-wrapper {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper.active {
  max-height: 10000px;
}
section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper.active .category-nav-inner .inner-more {
  opacity: 0;
  visibility: hidden;
}
section.section-popular .popular-flexbox .flexbox-body {
  width: 72%;
}
section.section-popular .popular-flexbox .flexbox-body .no-products {
  padding-top: 30px;
}
section.section-popular .popular-flexbox .flexbox-body .body-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 40px 0;
}
section.section-popular .popular-flexbox .flexbox-body .body-heading .heading {
  margin-bottom: 0;
}
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper {
  display: flex;
  align-items: center;
}
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
  margin-right: 30px;
  display: flex;
}
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a,
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button {
  margin-right: 15px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a img,
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button img {
  width: 15px;
  opacity: 0.5;
}
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a:last-child,
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button:last-child {
  margin-right: 0;
}
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a.active img,
section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button.active img {
  opacity: 1;
}
section.section-popular .popular-flexbox .flexbox-body .heading {
  font-size: 16px;
  letter-spacing: 2px;
  color: #777;
  position: relative;
  margin: 10px 0 40px 0;
  text-align: center;
}
section.section-popular .popular-flexbox .flexbox-body .heading a {
  color: inherit;
  transition: color 0.4s;
}
section.section-popular .popular-flexbox .flexbox-body .heading a:hover {
  color: #1d5ab5;
}
section.section-popular .popular-flexbox .flexbox-body .heading.big {
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0;
  color: #555;
}
section.section-popular .popular-flexbox .flexbox-body .heading:not(:first-of-type) {
  margin-top: 40px;
}
section.section-popular .popular-flexbox .flexbox-body .products-flexbox {
  padding: 0 30px 30px 30px;
  background: #f3f3f3;
}
section.section-popular .popular-flexbox .flexbox-body .products-flexbox .buttons {
  display: flex;
  flex-direction: column;
}
section.section-popular .popular-flexbox .flexbox-body .buttons {
  position: fixed;
  bottom: 20px;
  right: 50px;
  z-index: 222;
  display: flex;
  flex-direction: column;
}
section.section-popular .popular-flexbox .flexbox-body .buttons .btn-fixed {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  margin-top: -10px;
}
section.section-popular .popular-flexbox .flexbox-body .buttons .btn-fixed.active {
  opacity: 1;
  visibility: visible;
}
section.section-popular .popular-flexbox .flexbox-body .body-cta {
  margin-top: 40px;
  display: block;
  position: relative;
  transition: border-radius 0.4s;
  overflow: hidden;
}
section.section-popular .popular-flexbox .flexbox-body .body-cta img {
  width: 100%;
}
section.section-popular .popular-flexbox .flexbox-body .body-cta .cta-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  padding: 30px;
}
section.section-popular .popular-flexbox .flexbox-body .body-cta .cta-inner p {
  font-weight: 300;
  font-size: 24px;
  color: #fff;
}
section.section-popular .popular-flexbox .flexbox-body .body-cta .cta-inner p strong {
  font-size: 31px;
  font-weight: 700;
}
section.section-popular .popular-flexbox .flexbox-body .body-cta:hover {
  border-radius: 10px;
}
section.section-popular .popular-flexbox .flexbox-body .body-advantages {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
section.section-popular .popular-flexbox .flexbox-body .body-advantages .advantage-service {
  display: flex;
  align-items: center;
}
section.section-popular .popular-flexbox .flexbox-body .body-advantages .advantage-service img {
  height: 35px;
  margin-right: 8px;
  transition: all 0.4s;
}
section.section-popular .popular-flexbox .flexbox-body .body-advantages .advantage-service p {
  font-size: 12px;
  color: #777;
  line-height: 16px;
  transition: color 0.4s;
}
section.section-popular .popular-flexbox .flexbox-body .body-advantages .advantage-service:hover img {
  transform: translateY(-4px);
}
section.section-popular .popular-flexbox .flexbox-body .body-advantages .advantage-service:hover p {
  color: #1d5ab5;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel {
  margin-top: 40px;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .owl-stage-outer {
  height: 180px;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .item {
  height: 180px;
  transition: all 0.4s;
  overflow: hidden;
  border-radius: 3px;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .item img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .item:hover {
  border-radius: 10px;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .owl-controls .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .owl-controls .owl-dots .owl-dot {
  padding: 5px;
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .owl-controls .owl-dots .owl-dot span {
  height: 10px;
  min-width: 10px;
  padding: 0;
  margin: 0;
  transition: all 0.4s;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .owl-controls .owl-dots .owl-dot.active {
  width: 20px;
}
section.section-popular .popular-flexbox .flexbox-body .owl-carousel .owl-controls .owl-dots .owl-dot.active span {
  background: #1d5ab5;
  height: 15px;
  min-width: 15px;
}
section.section-popular .popular-flexbox .flexbox-body .body-more {
  margin-top: 65px;
  text-align: center;
}
section.section-popular .popular-flexbox .flexbox-body .body-more .btn {
  width: 400px;
  max-width: 100%;
}
section.section-popular .popular-flexbox .flexbox-body .body-product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery {
  width: 38%;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery img {
  width: 100%;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSPrev {
  width: 25px;
  height: 25px;
  background: url("../library/lightslider-master/dist/img/prev.svg") center;
  background-size: cover;
  margin-top: 0px;
  transform: translateY(-50%);
  left: 1px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSNext {
  width: 25px;
  height: 25px;
  background: url("../library/lightslider-master/dist/img/prev.svg") center;
  background-size: cover;
  margin-top: 0px;
  transform: translateY(-50%) rotate(180deg);
  right: 1px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery .lSGallery {
  margin-top: 23px !important;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery .lSGallery li {
  border: none;
  opacity: 0.6;
  transition: all 0.3s;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery .lSGallery li.active,
section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery .lSGallery li:hover {
  opacity: 1;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info {
  width: 55%;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info h2 {
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .code {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .code strong {
  font-weight: 700;
  color: #1d5ab5;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .description {
  margin-top: 20px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .description p {
  font-size: 14px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .features {
  margin-top: 20px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .features > *:not(:first-child) {
  margin-top: 10px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .features p {
  font-size: 16px;
  font-weight: 300;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .features p strong {
  font-weight: 700;
  color: #999;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox label {
  font-size: 16px;
  font-weight: 300;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper {
  position: relative;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper select {
  cursor: pointer;
  padding: 10px 100px 10px 10px;
  border: none;
  border: 1px solid #bbb;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  display: block;
  color: #666;
  width: 100%;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper:after {
  cursor: pointer;
  content: "";
  height: 10px;
  width: 10px;
  background: url("../img/pictures/down-chevron.svg") center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price {
  margin-top: 20px;
  padding: 23px 20px 20px 20px;
  background: #f1f1e5;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-netto {
  color: #1d5ab5;
  font-size: 40px;
  font-weight: 500;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-netto span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
  line-height: 15px;
  padding-left: 10px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-brutto {
  margin-top: 10px;
  font-weight: 400;
  color: #999;
  font-size: 18px;
  line-height: 18px;
  padding-left: 3px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability {
  margin-top: 20px;
  background: #fff;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-inner {
  display: flex;
  padding: 10px 10px 0;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-inner p {
  text-transform: uppercase;
  color: #000;
  font-size: 14px;
  letter-spacing: 0.3px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-inner p:nth-child(2) {
  margin-left: 10px;
  font-weight: 700;
  position: relative;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-inner p:nth-child(2):after {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-inner.red {
  border-top: 2px solid crimson;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-inner.red p:nth-child(2):after {
  background: crimson;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-inner.green {
  border-top: 2px solid olivedrab;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-inner.green p:nth-child(2):after {
  background: olivedrab;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-comment {
  padding: 10px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .price-availability .availability-comment p {
  font-size: 13px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper {
  margin-top: 20px;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner {
  display: flex;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number {
  width: 100%;
  padding: 0 12px;
  text-align: center;
  outline: none;
  background: none;
  border: none;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number,
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  height: 35px;
  user-select: none;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  min-width: 35px;
  background: none;
  color: #999;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s;
  cursor: pointer;
  border: 1px solid #999;
  outline: none;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement:active,
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment:active {
  background: #1d5ab5;
  color: #fff;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-inner-spin-button,
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"] {
  -moz-appearance: textfield;
}
section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn {
  margin-top: 20px;
  width: 100%;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper {
  padding: 30px;
  background: #f3f3f3;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package {
  display: flex;
  justify-content: space-between;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-image {
  width: 22.5%;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .service-image-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 7px;
  display: block;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  background: #fff;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .service-image-wrapper .service-image {
  text-align: center;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 7px;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .service-image-wrapper .service-image img {
  width: 100%;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info {
  width: 53.83333%;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info h2 {
  font-size: 36px;
  font-weight: 400;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info .price {
  margin-top: 20px;
  color: #1d5ab5;
  font-size: 35px;
  font-weight: 500;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info .price span {
  font-weight: 400;
  color: #999;
  font-size: 16px;
  line-height: 15px;
  padding-left: 7px;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info .date {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 300;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info .date strong {
  font-weight: 700;
  color: #1d5ab5;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info .btn-more {
  font-size: 13px;
  text-decoration: underline !important;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-gift {
  width: 19%;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-gift .heading {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .collapse-terms {
  margin-top: 30px;
  background: #fff;
  padding: 30px;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .collapse-terms p,
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .collapse-terms li {
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.15px;
}
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .collapse-terms ul,
section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .collapse-terms ol {
  padding-left: 17px;
}
section.kategoria-section-heading {
  position: relative;
  height: 150px;
  overflow: hidden;
}
section.kategoria-section-heading img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) scaleX(-1);
}
section.kategoria-section-heading .heading-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
section.kategoria-section-heading .heading-inner p {
  font-weight: 300;
  font-size: 24px;
  color: #fff;
}
section.kategoria-section-heading .heading-inner h1 {
  font-size: 48px;
  color: #fff;
}
section.kategoria-section-heading .heading-inner h1 a {
  color: inherit;
  transition: all 0.4s;
}
section.kategoria-section-heading .heading-inner h1 a:hover {
  padding-left: 10px;
}
section.static-page-section-content {
  padding: 70px 0;
}
section.static-page-section-content .content-heading {
  text-align: center;
}
section.static-page-section-content .content-heading h2 {
  font-size: 36px;
  font-weight: 400;
}
section.static-page-section-content .content-inner {
  margin-top: 40px;
}
section.static-page-section-content .content-inner > *:not(:first-child) {
  margin-top: 20px;
}
section.static-page-section-content .content-inner p,
section.static-page-section-content .content-inner li {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.2px;
}
section.static-page-section-content .content-inner ul,
section.static-page-section-content .content-inner ol {
  padding-left: 17px;
}
section.kontakt-section-content {
  padding: 70px 0;
  position: relative;
}
section.kontakt-section-content .service-heading {
  padding-top: 20px;
}
section.kontakt-section-content .service-heading h3 {
  font-size: 32px;
}
section.kontakt-section-content .service-content a {
  font-weight: 700;
  color: #1d5ab5;
}
section.kontakt-section-content .service-content h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
section.kontakt-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
section.kontakt-section-content .content-flexbox .flexbox-heading {
  width: 50%;
  margin-bottom: 20px;
}
section.kontakt-section-content .content-flexbox .flexbox-heading h2 {
  color: #444;
  font-size: 22px;
  margin-bottom: 10px;
}
section.kontakt-section-content .content-flexbox .flexbox-service {
  width: 33%;
}
section.kontakt-section-content .content-flexbox .flexbox-service a {
  font-weight: 700;
  color: #1d5ab5;
}
section.kontakt-section-content .content-flexbox .flexbox-service h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
section.kontakt-section-content .content-flexbox .flexbox-address {
  width: 33%;
}
section.kontakt-section-content .content-flexbox .flexbox-address h3 {
  color: #1d5ab5;
  font-size: 45px;
  font-weight: 800;
}
section.kontakt-section-content .content-flexbox .flexbox-address address {
  margin: 10px 0;
  color: #666;
  font-size: 18px;
  font-weight: 400;
}
section.kontakt-section-content .content-flexbox .flexbox-address address small {
  margin-top: 20px;
  display: block;
  color: #999;
  margin-bottom: 7px;
}
section.kontakt-section-content .content-flexbox .flexbox-address address a {
  font-weight: 700;
  color: #1d5ab5;
}
section.kontakt-section-content .content-flexbox .flexbox-address p {
  color: #666;
}
section.kontakt-section-content .content-flexbox .iframe-wrapper {
  position: relative;
  width: 100%;
  height: 450px;
  margin-top: 40px;
}
section.kontakt-section-content .content-flexbox .iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
#return-to-top {
  position: fixed;
  z-index: 333;
  bottom: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d5ab5;
  transition: all 0.6s;
  visibility: hidden;
  transform: rotate(-180deg);
  opacity: 0;
}
#return-to-top svg {
  fill: #fff;
  height: 15px;
  width: 15px;
}
#return-to-top:hover {
  background-color: #0e254f;
}
#return-to-top.active {
  opacity: 1;
  visibility: visible;
  transform: none;
}
section.section-footer {
  padding: 0 0 50px 0;
  background: #525252;
  position: relative;
}
section.section-footer .footer-logo-wrapper {
  display: flex;
  justify-content: center;
}
section.section-footer .footer-logo-wrapper .footer-logo {
  transform: translateY(-10px);
  background: #1d5ab5;
  padding: 30px 25px;
  box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.24);
  transition: all 0.4s;
}
section.section-footer .footer-logo-wrapper .footer-logo p {
  color: #fff;
  transform: translateY(-5px);
}
section.section-footer .footer-logo-wrapper .footer-logo p:nth-child(1) {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 5px;
  text-transform: uppercase;
}
section.section-footer .footer-logo-wrapper .footer-logo p:nth-child(2) {
  font-size: 13px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 1.8px;
  padding-left: 1px;
}
section.section-footer .footer-logo-wrapper .footer-logo p:nth-child(2) strong {
  font-weight: 700;
}
section.section-footer .footer-logo-wrapper .footer-logo:hover {
  padding: 30px 35px;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service {
  margin-top: 50px;
}
section.section-footer .footer-flexbox .flexbox-service .service-logo {
  height: 100px;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li.heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 16px;
  color: #e6e6e5;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 12px;
  color: #e6e6e5;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #1d5ab5;
}
section.section-footer .footer-flexbox .flexbox-service ul li p {
  font-size: 12px;
  color: #e6e6e5;
}
section.section-footer .footer-flexbox .flexbox-service ul li p b {
  font-weight: 400;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 25px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .footer-credits {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits p {
  font-size: 12px;
  color: #e6e6e5;
  font-weight: 300;
}
section.section-footer .footer-credits p a {
  color: #e6e6e5;
}
section.section-footer .footer-credits .footer-payment {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits .footer-payment p {
  margin-right: 15px;
}
section.section-footer .footer-credits .footer-payment img {
  margin-right: 10px;
  height: 19px;
}
section.section-footer .footer-credits .footer-payment img:last-child {
  margin-right: 0;
}
section.section-footer .alert {
  position: relative;
  margin-top: 50px;
  margin-bottom: 0;
  padding: 50px 0 0 0;
  background: none;
  border: none;
  border-top: 1px solid #666;
}
section.section-footer .alert p {
  font-size: 12px;
  color: #e6e6e5;
  font-weight: 300;
}
section.section-footer .alert p a {
  color: #e6e6e5;
  cursor: pointer;
}
@media (max-width: 1199.98px) {
  section.section-popular .popular-flexbox .flexbox-body .products-flexbox.grid-list .product-service {
    align-items: center;
  }
  section.section-popular .popular-flexbox .flexbox-body .products-flexbox.grid-list .product-service header .service-image-wrapper {
    width: 80%;
    padding-top: 80%;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown {
    margin-top: 0;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .nav-button .button-bar {
    background: #1d5ab5;
  }
  nav.section-header .header-flexbox .flexbox-logo p:nth-child(1) {
    font-size: 24px;
  }
  nav.section-header .header-flexbox .flexbox-logo p:nth-child(2) {
    font-size: 11px;
  }
  nav.section-header .header-flexbox .flexbox-top ul li {
    margin: 0 10px;
  }
  nav.section-header .header-flexbox .flexbox-top ul li .price-badge.hurt {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-top ul li a {
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 12px;
  }
  nav.section-header .header-flexbox .flexbox-top ul li a svg {
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-top ul li a p {
    font-size: 9px;
  }
  nav.section-header .header-flexbox .flexbox-top ul li a.active span {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-service {
    padding: 10px 0 10px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    padding: 10px 15px;
    font-size: 20px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile {
    display: inline-block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact {
    margin-top: 10px;
    margin-left: 0;
    padding: 10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact .btn-small {
    font-size: 16px;
    padding: 5px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact .phone {
    font-size: 13px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  .products-flexbox .product-service {
    width: 31%;
  }
  .products-flexbox .product-service .btn-more {
    padding: 12px;
  }
  .products-flexbox .product-service .name {
    font-size: 12.5px;
  }
}
@media (max-width: 890px) {
  section.section-popular .popular-flexbox .flexbox-body {
    padding-top: 60px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-advantages {
    margin-top: 20px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-advantages .advantage-service {
    padding-top: 20px;
  }
  section.section-popular .popular-flexbox .flexbox-body .heading {
    margin-bottom: 20px;
    font-size: 14px;
  }
  section.section-popular .popular-flexbox .flexbox-body .select-wrapper select {
    padding: 5px 50px 5px 10px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-product {
    flex-direction: column;
    align-items: center;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-product .product-gallery,
  section.section-popular .popular-flexbox .flexbox-body .body-product .product-info {
    width: 70%;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-product .product-info {
    padding-top: 50px;
  }
}
@media (max-width: 800px) {
  .products-flexbox .product-service {
    align-items: center;
    width: 48%;
  }
  .products-flexbox .product-service .badge-wrapper {
    left: 23px;
  }
  .products-flexbox .product-service .service-quantity .quantity-inner-input-wrapper {
    margin: 0 5px;
  }
  .products-flexbox .product-service .footer-quantity.active {
    width: 170px;
  }
  .products-flexbox.wyszukiwanie .product-service {
    width: 48%;
  }
  section.section-popular {
    padding: 0 0 70px 0;
  }
  section.section-popular .popular-flexbox {
    padding-top: 30px;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav {
    padding-top: 0;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .shadow-heading {
    display: none;
  }
  section.section-popular .popular-flexbox .flexbox-body {
    padding-top: 0;
  }
  section.kontakt-section-content {
    padding: 50px 0;
  }
  section.kontakt-section-content .service-heading {
    text-align: center;
  }
  section.kontakt-section-content .service-heading h3 {
    font-size: 24px;
  }
  section.kontakt-section-content .service-content {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .content-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.kontakt-section-content .content-flexbox .flexbox-address,
  section.kontakt-section-content .content-flexbox .flexbox-service,
  section.kontakt-section-content .content-flexbox .flexbox-heading,
  section.kontakt-section-content .content-flexbox .iframe-wrapper {
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .content-flexbox .flexbox-service {
    margin-top: 20px;
  }
  section.kontakt-section-content .content-flexbox .iframe-wrapper {
    margin-top: 20px;
    height: 250px;
  }
}
@media (max-width: 600px) {
  nav.section-header .header-search-engine input {
    padding: 10px;
  }
  nav.section-header .header-search-engine .btn-search {
    padding: 0 15px;
  }
  nav.section-header .header-flexbox .flexbox-top ul li {
    margin-right: 15px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    padding: 10px 0;
  }
  section.section-welcome .welcome-inner h2 {
    font-size: 28px;
  }
  section.section-welcome .welcome-inner h3 {
    font-size: 16px;
    margin-top: 0;
  }
  section.section-popular .popular-flexbox {
    padding-top: 0;
    flex-direction: column;
  }
  section.section-popular .popular-flexbox .nav-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .heading {
    display: none;
  }
  section.section-popular .popular-flexbox .flexbox-body {
    padding-top: 0;
    margin-top: 0;
  }
  section.section-popular .popular-flexbox .flexbox-body .heading {
    font-size: 24px;
  }
  section.section-popular .popular-flexbox .flexbox-body .products-flexbox .product-service header {
    width: 80%;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-heading {
    flex-direction: column;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper {
    padding-top: 20px;
    justify-content: center;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
    display: none;
  }
  section.section-popular .popular-flexbox .flexbox-body .select-wrapper select {
    padding: 5px 25px 5px 5px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-advantages {
    margin-top: 20px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-advantages .advantage-service {
    min-width: 48%;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-cta .cta-inner p {
    font-size: 16px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-cta .cta-inner p strong {
    font-size: 20px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-image {
    width: 35.5%;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info {
    width: 70%;
    text-align: center;
    padding-top: 20px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info .price {
    justify-content: center;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-info .date {
    font-size: 20px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-gift {
    width: 45%;
    padding-top: 20px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-package-wrapper .body-package .package-gift .heading {
    font-size: 13px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-product .product-info {
    padding-top: 20px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-product .product-info h2 {
    font-size: 22px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner {
    display: flex;
    justify-content: space-between;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .quantity-inner-input {
    width: 115px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-more .btn {
    padding: 15px 0;
  }
  section.section-popular .popular-flexbox .flexbox-body .btn-fixed:nth-of-type(1) {
    bottom: 80px;
    right: 190px;
  }
  section.section-popular .popular-flexbox .flexbox-body .btn-fixed:nth-of-type(2) {
    right: 198px;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
    display: block;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    padding: 50px;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a {
    font-size: 18px;
    padding: 8px 0;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li a {
    font-size: 15px;
  }
  section.section-popular .popular-flexbox .flexbox-sidebar .category-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  section.section-popular .popular-flexbox .flexbox-body {
    margin-top: 20px;
    width: 100%;
  }
  section.kategoria-section-heading {
    height: 100px;
  }
  section.kategoria-section-heading img {
    height: 100%;
  }
  section.kategoria-section-heading .heading-inner h1 {
    font-size: 40px;
  }
  section.kategoria-section-heading .heading-inner p {
    font-size: 22px;
  }
  section.static-page-section-content {
    padding: 50px 0;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    margin-top: 30px;
  }
  section.section-footer .footer-flexbox .flexbox-service ul {
    text-align: center;
  }
  section.section-footer .footer-credits {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 400px) {
  .btn {
    padding: 8px 25px;
  }
  .products-flexbox .product-service {
    width: 100%;
  }
  .products-flexbox .product-service .service-image-wrapper .service-image .order-more {
    right: 27%;
  }
  .products-flexbox.wyszukiwanie .product-service {
    width: 100%;
  }
  nav.section-header .header-flexbox .flexbox-top ul li a {
    font-size: 10px;
  }
  nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown {
    left: 0;
    width: 330px;
  }
  nav.section-header .flexbox-dropdown-wrapper .flexbox-dropdown .dropdown-list li p {
    font-size: 11px;
    margin-right: 20px;
  }
  section.section-welcome {
    height: 100px;
  }
  section.kategoria-section-heading {
    height: 80px;
  }
  section.kategoria-section-heading .heading-inner h1 {
    font-size: 26px;
  }
  section.kategoria-section-heading .heading-inner p {
    font-size: 14px;
  }
  .breadcrumb-wrapper .breadcrumb .breadcrumb-item {
    font-size: 10px;
  }
  section.section-popular .popular-flexbox .flexbox-body .buttons {
    right: 0;
    left: 0;
    align-items: center;
  }
  section.section-popular .popular-flexbox .flexbox-body .no-products {
    font-size: 14px;
  }
  section.section-popular .popular-flexbox .flexbox-body .products-flexbox.grid-list .product-service {
    flex-direction: column;
  }
  section.section-popular .popular-flexbox .flexbox-body .products-flexbox.grid-list .product-service header .service-image-wrapper {
    width: 100%;
    padding-top: 100%;
  }
  section.section-popular .popular-flexbox .flexbox-body .products-flexbox.grid-list .product-service .name {
    width: 100%;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-heading {
    margin: 0 0 40px 0;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-cta {
    height: 100px;
  }
  section.section-popular .popular-flexbox .flexbox-body .body-cta img {
    height: 100%;
  }
}
