@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900&display=swap&subset=latin-ext");

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  .container-fluid {
    max-width: 1260px;
  }

  .btn {
    padding: 8px 30px;
    border: none;
    border-radius: 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.4s;

    &.btn-default {
      background: @color1;
      color: #fff;

      &:hover {
        background: darken(@color1, 10%);
      }
    }

    &.btn-small {
      font-size: 11px;
      padding: 6px 20px;
    }
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }
}

@color1: #1d5ab5;

.select-wrapper {
  position: relative;

  select {
    cursor: pointer;
    padding: 5px 100px 5px 10px;
    border: none;
    border-bottom: 2px solid #bbb;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 13px;
    appearance: none;
    outline: none;
    display: block;
    color: #666;
    width: 100%;
  }

  &:after {
    cursor: pointer;
    content: "";
    height: 10px;
    width: 10px;
    background: url("../img/pictures/down-chevron.svg") center;
    background-size: cover;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  .alert-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    transform: translateX(8px);
    padding: 50px 0;

    .service-inner {
      padding: 50px;
      background: #fff;
      width: 100%;
      box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-top: 5px solid #fff;
      position: relative;
      transform: scale(0.8);
      transition: all 0.5s;

      .inner-dismiss {
        top: 10px;
        right: 10px;
        position: absolute;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        padding: 5px;
        outline: none;

        img {
          height: 15px;
        }
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .alert-service {
      .service-inner {
        transform: none;
      }
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-service {
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 700px;
      max-width: 100%;
      padding: 50px 25px 25px 25px;
      text-align: center;
      position: relative;

      .service-button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        .heading {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .btn {
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .product-service {
    margin-top: 30px;
    width: 22.5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .badge-wrapper {
      position: absolute;
      z-index: 111;
      top: 0;
      left: 0;

      .badge {
        width: 70px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        font-size: 9px;
        border-radius: 2px;

        &.promotion {
          background: orangered;
          display: none;
        }

        &.new {
          background: seagreen;
          display: none;
        }

        &.bestseller {
          background: lightseagreen;
          display: none;
        }

        &:not(:first-child) {
          margin-top: 5px;
        }
      }
    }

    .service-image-wrapper {
      width: 100%;
      padding-top: 100%;
      position: relative;
      border-radius: 7px;
      display: block;
      box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.4s;
      background: #fff;

      .service-image {
        text-align: center;
        position: relative;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 7px;

        .order-more {
          position: absolute;
          bottom: 10px;
          right: 25%;
          width: 90px;
          padding: 3px;
          border-radius: 1.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #fff;
          font-size: 10px;
          font-weight: 600;
          background-color: #1d5ab5;
          display: none;
          box-shadow: 0 0 0 rgba(#1d5ab5, 0.4);
          animation: pulse 2s infinite ease-in-out;
        }

        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(#1d5ab5, 0.4);
          }
          70% {
            box-shadow: 0 0 0 8px rgba(#1d5ab5, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(#1d5ab5, 0);
          }
        }

        img {
          width: 100%;
        }
      }

      &.active {
        box-shadow: 0px 10px 15px 0px rgba(211, 14, 14, 0.3);
      }
    }

    .service-quantity {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-weight: 700;
        font-size: 11px;
      }

      input {
        width: 70px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid @color1;
        font-size: 14px;
        outline: none;
        padding: 0 20px 0 10px;
        transition: all 0.4s;
        text-align: center;
        -webkit-appearance: none;
        appearance: none;

        &.active {
          border: 1px solid rgba(211, 14, 14, 1);
        }

        &:hover {
          .button-quantity-wrapper {
            opacity: 1;
          }
        }
      }

      button {
        height: 25px;
        width: 25px;
        border-radius: 5px;
        border: none;
        background: @color1;
        color: #fff;
        cursor: pointer;
        transition: background 0.4s;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 11px;
        }

        &:hover {
          background: darken(@color1, 10%);
        }
      }

      .quantity-inner-input-wrapper {
        position: relative;

        .button-quantity-wrapper {
          position: absolute;
          display: flex;
          flex-direction: column;
          opacity: 0;
          right: 3px;
          width: 17px;
          height: 22px;
          top: 1.5px;
          background: #f6f6f6;
          cursor: none;

          .quantity-button-number {
            &.increment,
            &.decrement {
              position: absolute;
              right: 0;
              width: 100%;
              height: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
              border-radius: 0;
              transition: none;

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 7px;
              }
            }

            &.increment {
              top: 0;
              background: #ddd;

              &.active {
                background: #f6f6f6;
              }
            }

            &.decrement {
              bottom: 0;
              background: #ddd;

              &.active {
                background: #f6f6f6;
              }
            }

            &:active,
            &:focus {
              outline: none;
            }
          }

          &.active {
            opacity: 1;
          }

          &:hover {
            background: #f6f6f6;
            opacity: 1;
          }
        }
      }
    }

    .name {
      margin-top: 20px;
      color: #333;
      font-weight: 500;
      font-size: 14.5px;
      display: block;
      transition: all 0.4s;
    }

    .price {
      margin-top: 10px;
      color: @color1;
      font-size: 23px;
      font-weight: 600;

      small {
        font-size: 14px;
        font-weight: 400;
        color: #444;
        margin-right: 5px;
        position: relative;
        display: none;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: -3px;
          width: 115%;
          height: 1px;
          background: #000;
          transform: rotate(-10deg);
        }
      }

      span {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
    }

    .price-small {
      font-size: 12px;
      color: #999;

      small {
        font-weight: 400;
        color: #999;
        margin-right: 5px;
        position: relative;
        display: none;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: -1px;
          width: 110%;
          height: 1px;
          background: #222;
          transform: rotate(-10deg);
        }
      }
    }

    .type {
      margin-top: 10px;
      font-size: 12px;
      color: #999;

      strong {
        color: @color1;
      }
    }

    .footer-quantity {
      margin-top: 15px;
      padding: 5px 8px;
      background: #f3f3f3;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;

      &.active {
        background: #fff;
      }

      p {
        font-size: 11px;
        color: #333;

        &:nth-child(2) {
          font-weight: 700;
          color: @color1;
        }
      }
    }

    .btn-more {
      margin-top: 17px;
      background: #999;
      width: 100%;
      padding: 3px;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 2px;
      color: #fff;
      border-radius: 4px;
      display: block;
      border: none;
      transition: all 0.4s;
    }

    &:hover {
      .service-image-wrapper {
        transform: translateY(-4px);
        box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.1);

        &.active {
          box-shadow: 0px 20px 15px 0px rgba(211, 14, 14, 0.3);
        }
      }

      .name {
        color: @color1;
      }

      .btn-more {
        background: @color1;
      }
    }

    &.promotion {
      .price {
        color: #ff4500;

        small {
          display: inline-block;
        }
      }

      .price-small {
        small {
          display: inline-block;
        }
      }
    }

    &.service-hidden {
      margin: 0;
      padding: 0;
      visibility: hidden;
      opacity: 0;
    }
  }

  &.grid-list {
    .product-service {
      width: 100% !important;
      flex-direction: row;

      .service-image-wrapper {
        width: 20%;
        padding-top: 20%;

        position: relative;
        border-radius: 7px;
        display: block;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.4s;
        background: #fff;

        .service-image {
          text-align: center;
          position: relative;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          border-radius: 7px;

          img {
            width: 100%;
          }
        }

        &.active {
          box-shadow: 0px 10px 15px 0px rgba(211, 14, 14, 0.3);
        }
      }

      .service-quantity {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-weight: 700;
          font-size: 11px;
        }

        input {
          width: 70px;
          height: 25px;
          border-radius: 5px;
          border: 1px solid @color1;
          font-size: 14px;
          outline: none;
          padding: 0;
          transition: all 0.4s;
          text-align: center;
          -webkit-appearance: none;
          appearance: none;

          &.active {
            border: 1px solid rgba(211, 14, 14, 1);
          }
        }

        button {
          height: 25px;
          width: 25px;
          border-radius: 5px;
          border: none;
          background: @color1;
          color: #fff;
          cursor: pointer;
          transition: background 0.4s;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 11px;
          }

          &:hover {
            background: darken(@color1, 10%);
          }
        }
      }

      .name {
        margin-top: 20px;
        color: #333;
        font-weight: 500;
        font-size: 14.5px;
        display: block;
        transition: all 0.4s;
      }

      .price {
        margin-top: 10px;
        color: @color1;
        font-size: 23px;
        font-weight: 600;

        span {
          font-weight: 400;
          color: #999;
          font-size: 12px;
        }
      }

      .price-small {
        font-size: 12px;
        color: #999;
      }

      .type {
        margin-top: 10px;
        font-size: 12px;
        color: #999;

        strong {
          color: @color1;
        }
      }

      .footer-quantity {
        margin-top: 15px;
        padding: 5px 8px;
        background: #fff;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;

        p {
          font-size: 11px;
          color: #333;

          &:nth-child(2) {
            font-weight: 700;
            color: @color1;
          }
        }
      }

      .btn-more {
        margin-top: 17px;
        background: #999;
        width: 100%;
        padding: 3px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 2px;
        color: #fff;
        border-radius: 4px;
        display: block;
        border: none;
        transition: all 0.4s;
      }

      &:hover {
        .service-image-wrapper {
          transform: translateY(-4px);
          box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.1);

          &.active {
            box-shadow: 0px 20px 15px 0px rgba(211, 14, 14, 0.3);
          }
        }

        .name {
          color: @color1;
        }

        .btn-more {
          background: @color1;
        }
      }

      &.service-hidden {
        margin: 0;
        padding: 0;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &.wyszukiwanie {
    .product-service {
      width: 18%;
    }
  }
}

.breadcrumb-wrapper {
  .breadcrumb {
    border-radius: 0;
    padding: 10px 20px;
    background: #f3f3f3;
    margin-bottom: 0;

    .breadcrumb-item {
      font-size: 12px;
      font-weight: 500;

      a {
        color: @color1;
      }
    }
  }
}

.pagination-nav {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
    a {
      display: block;
      padding: 10px;
      color: #999;
      text-transform: uppercase;
    }

    &.active {
      a {
        font-weight: 700;
        color: @color1;
      }
    }
  }
}

section.section-alert {
  background: #c12525;
  padding: 5px 0;

  p {
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 13px;
  }
}

nav.section-header {
  &.margin-bottom {
    margin-bottom: 70px;
  }

  .header-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .flexbox-logo {
      border-top: 2px solid @color1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        color: @color1;
        transform: translateY(-5px);

        &:nth-child(1) {
          font-size: 31px;
          font-weight: 900;
          font-style: italic;
          letter-spacing: 5px;
          text-transform: uppercase;
        }

        &:nth-child(2) {
          font-size: 15.3px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 1.8px;
          padding-left: 1px;

          strong {
            font-weight: 700;
          }
        }
      }
    }

    .flexbox-service {
      padding: 25px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
    }

    .flexbox-top {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        align-items: center;

        li {
          margin-right: 40px;
          display: flex;
          align-items: center;

          .price-badge {
            margin-right: 10px;
            cursor: pointer;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            font-size: 10px;
            color: #fff;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            &.hurt {
              background: olivedrab;
            }

            &.detal {
              background: teal;
            }
          }

          a {
            display: flex;
            align-items: center;
            font-size: 14px;
            transition: all 0.4s;
            color: #333;
            font-weight: 500;
            cursor: pointer;

            svg {
              height: 20px;
              margin-right: 10px;
              fill: #333;
              transition: fill 0.4s;
            }

            &:hover {
              opacity: 0.6;
            }

            &.active {
              color: @color1;
              font-weight: 600;

              span {
                font-weight: 400;
                color: #333;
                font-size: 13px;
                padding-left: 7px;
              }

              svg {
                fill: darken(@color1, 10%);
              }

              &:hover {
                color: darken(@color1, 10%);
                opacity: 0.7;

                svg {
                  fill: darken(@color1, 20%);
                }
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .flexbox-nav-wrapper {
      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 20px;
        left: 0;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        .flexbox-nav {
          display: flex;
          align-items: center;

          li {
            a {
              color: #555;
              font-weight: 500;
              font-size: 15px;
              padding: 35px 14px;
              display: block;
            }

            &.mobile {
              display: none;
            }

            &.contact {
              margin-left: 20px;
              padding: 7px;
              border-radius: 8px;
              background: #f3f3f3;
              display: flex;
              align-items: center;

              .btn-small {
                font-size: 12px;
                color: #fff;
                padding-top: 4px;
                padding-bottom: 4px;
                border-radius: 6px;
              }

              .phone {
                padding: 0 13px 0 20px;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                align-items: center;
                transition: all 0.4s;

                img {
                  margin-right: 7px;
                  height: 15px;
                }

                &:hover {
                  color: @color1;
                }
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: #fff;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }
  }

  .flexbox-dropdown-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;

    .flexbox-dropdown {
      margin-top: -66px;
      position: absolute;
      z-index: 111;
      padding: 20px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      width: 460px;

      .dropdown-empty {
        font-size: 14px;
        padding: 10px 50px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.2);
        color: #333;
        font-weight: 500;
        text-align: center;
      }

      .heading {
        text-align: right;
      }

      .dropdown-list {
        margin-top: 20px;

        li {
          font-size: 13px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            width: 100%;
            margin-right: 30px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          span {
            font-weight: 700;
            padding: 3px 8px;
            display: block;
            background: #efefef;
            border-radius: 3px;
            white-space: nowrap;
          }

          &:not(:first-child) {
            margin-top: 3px;
            padding-top: 3px;
            border-top: 1px solid #eee;
          }
        }
      }

      .dropdown-summary {
        padding: 15px 0 20px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p {
          font-size: 16px;
          font-weight: 300;

          strong {
            color: @color1;
            font-weight: 700;
          }

          &:nth-child(1) {
            margin-right: 10px;
          }
        }
      }

      .dropdown-more {
        text-align: right;
        margin-top: 20px;

        a {
          font-weight: 700;
          color: @color1;
          font-size: 13px;
        }
      }

      .dropdown-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn {
          width: 100%;
        }
      }
    }

    &.opacity-hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  .header-search-engine-wrapper {
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 222;
    }
  }

  .header-search-engine {
    padding: 15px;
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    transition: all 0.4s;

    &.active {
      background: #ddd;
    }

    input {
      width: 100%;
      margin-right: 15px;
      border-radius: 12px;
      height: 40px;
      padding: 10px 15px;
      border: 1px solid #fff;
      transition: all 0.4s;

      &:focus {
        outline: none;
        border: 1px solid @color1;
      }

      &.active {
        height: 48px;
        border: 1px solid lighten(@color1, 30%);

        &:focus {
          border: 1px solid @color1;
        }
      }

      &::placeholder {
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        color: #999;
      }
    }

    .btn-search {
      padding: 0px 30px;
      background: @color1;
      border: none;
      border-radius: 12px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: background 0.4s;

      img {
        height: 14px;
        margin-right: 10px;
      }

      &:hover {
        background: darken(@color1, 10%);
      }
    }
  }
}

section.section-welcome {
  position: relative;
  height: 220px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .welcome-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    h2 {
      font-size: 48px;
      color: #fff;
    }

    h3 {
      margin-top: 10px;
      font-weight: 300;
      font-size: 24px;
      color: #fff;
    }
  }
}

section.section-popular {
  padding: 40px 0 70px;

  .popular-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    // .category-nav-button {
    // 	outline: none;
    // 	display: none;
    // 	width: 100%;
    // }

    .nav-button {
      outline: none;
      padding: 12px 30px;
      border: none;
      font-size: 14px;
      transition: all 0.3s;
      display: none;
      cursor: pointer;
      position: relative;
      background: #164489;
      border: none;
      border-radius: 12px;
      color: #fff;
      cursor: pointer;

      img {
        height: 22px;
        margin-right: 10px;
      }

      &:hover {
        background: @color1;
      }
    }

    .flexbox-sidebar {
      width: 23%;
      position: relative;

      .shadow-heading {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-45%, 0);
        font-weight: 900;
        color: #efefef;
        font-size: 60px;
        // opacity: 0.8;
      }

      .heading {
        font-size: 16px;
        letter-spacing: 2px;
        color: #777;
        position: relative;
        margin-bottom: 10px;
      }

      .category-nav-wrapper {
        position: relative;
        height: 100%;
        margin-top: 40px;
        // transition: all 8s;
        // -moz-transition: 8s;
        // -ms-transition: 8s;
        // -o-transition: 8s;
        // -webkit-transition: 8s;
        // transition: 8s;
        // overflow: hidden;

        .nav-button-close {
          position: absolute;
          display: none;
          width: 100%;
          top: 20px;
          left: -10px;

          .container {
            display: flex;
            justify-content: flex-end;

            &:before,
            &:after {
              content: none;
            }
          }

          button {
            outline: none;
            border: none;
            background: none;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .button-bar {
              display: block;
              position: absolute;
              width: 30px;
              height: 2px;
              background: #222;

              &:nth-child(1) {
                transform: rotate(45deg);
              }

              &:nth-child(2) {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .category-nav {
          padding-top: 18px;

          li {
            list-style: none;

            a {
              color: #333;
              padding: 5px 0;
              font-size: 20px;
              transition: all 0.4s;
              display: block;
              text-transform: lowercase;

              &:first-letter {
                text-transform: uppercase;
              }

              &:hover {
                color: @color1;
                padding-left: 5px;
              }
            }

            ul {
              padding-left: 20px;

              li {
                a {
                  padding: 5px 0;
                  display: block;
                  font-size: 15px;
                  color: #777;
                }
              }
            }

            &.active {
              > a {
                font-weight: 700;
                color: @color1;
                padding-left: 0;

                &:hover {
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .filter-nav {
          .small-heading {
            margin-top: 50px;
            color: #333;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 20px;
            text-transform: uppercase;
          }

          ul {
            li {
              .checkbox-wrapper {
                cursor: pointer;
                display: block;
                margin-bottom: 0;

                .radio-box {
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  color: #000;
                  letter-spacing: 0.8px;
                  font-weight: 300;
                  text-align: left;
                  transition: all 0.3s;

                  &:before {
                    content: "";
                    min-width: 16px;
                    max-width: 16px;
                    height: 16px;
                    display: block;
                    background: url("../img/icons/checkbox.svg") no-repeat;
                    margin-right: 10px;
                    transition: all 0.3s;
                  }
                }

                input {
                  visibility: hidden;
                  position: absolute;
                  width: auto !important;
                }
              }

              .checkbox-wrapper > input:checked + .radio-box {
                color: @color1;
                font-weight: 500;

                &:before {
                  background: url("../img/icons/checkbox-checked.svg") no-repeat;
                }
              }
            }
          }

          .inputs-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
              width: 100%;
              height: 30px;
              background: #e0e1dc;
              border: none;
              text-align: center;
              padding: 0 5px;
            }

            input:placeholder {
              color: #414141;
              font-size: 13px;
              font-weight: 300;
            }

            span {
              padding: 0 20px;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              margin: 0;
            }

            input[type="number"] {
              -moz-appearance: textfield;
            }
          }

          .range-slider {
            width: 100%;
            margin: auto;
            margin-top: 20px;
            text-align: center;
            position: relative;

            .slider-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;

              input[type="number"] {
                border: none;
                margin: 0 10px;
                outline: none;
                text-align: center;
                font-size: 14px;
                height: 30px;
                border-bottom: 1px solid @color1;
                width: 100%;
                max-width: 65px;
                -moz-appearance: textfield;
              }

              input[type="number"]::-webkit-outer-spin-button,
              input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }

              input[type="number"]:invalid,
              input[type="number"]:out-of-range {
                border: 1px solid #ff6347;
              }

              span {
                display: block;
                min-width: 12px;
              }

              p {
                font-size: 13px;
              }
            }
          }

          .buttons-wrapper {
            margin-top: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        &.active {
          max-height: 10000px;

          .category-nav-inner {
            .inner-more {
              opacity: 0;
              visibility: hidden;
            }
          }
        }
      }
    }

    .flexbox-body {
      width: 72%;

      .no-products {
        padding-top: 30px;
      }

      .body-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 40px 0;

        .heading {
          margin-bottom: 0;
        }

        .inner-wrapper {
          display: flex;
          align-items: center;

          .heading-grid {
            margin-right: 30px;
            display: flex;

            a,
            button {
              margin-right: 15px;
              padding: 0;
              border: none;
              background: none;
              cursor: pointer;

              img {
                width: 15px;
                opacity: 0.5;
              }

              &:last-child {
                margin-right: 0;
              }

              &.active {
                img {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .heading {
        font-size: 16px;
        letter-spacing: 2px;
        color: #777;
        position: relative;
        margin: 10px 0 40px 0;
        text-align: center;

        a {
          color: inherit;
          transition: color 0.4s;

          &:hover {
            color: @color1;
          }
        }

        &.big {
          font-size: 32px;
          font-weight: 300;
          letter-spacing: 0;
          color: #555;
        }

        &:not(:first-of-type) {
          margin-top: 40px;
        }
      }

      .products-flexbox {
        padding: 0 30px 30px 30px;
        background: #f3f3f3;

        .buttons {
          display: flex;
          flex-direction: column;
        }
      }

      .buttons {
        position: fixed;
        bottom: 20px;
        right: 50px;
        z-index: 222;
        display: flex;
        flex-direction: column;

        .btn-fixed {
          opacity: 0;
          visibility: hidden;
          transition: all 0.4s;
          margin-top: -10px;

          &.active {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .body-cta {
        margin-top: 40px;
        display: block;
        position: relative;
        transition: border-radius 0.4s;
        overflow: hidden;

        img {
          width: 100%;
        }

        .cta-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          text-align: right;
          padding: 30px;

          p {
            font-weight: 300;
            font-size: 24px;
            color: #fff;

            strong {
              font-size: 31px;
              font-weight: 700;
            }
          }
        }

        &:hover {
          border-radius: 10px;
        }
      }

      .body-advantages {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .advantage-service {
          display: flex;
          align-items: center;

          img {
            height: 35px;
            margin-right: 8px;
            transition: all 0.4s;
          }

          p {
            font-size: 12px;
            color: #777;
            line-height: 16px;
            transition: color 0.4s;
          }

          &:hover {
            img {
              transform: translateY(-4px);
            }

            p {
              color: @color1;
            }
          }
        }
      }

      .owl-carousel {
        margin-top: 40px;

        .owl-stage-outer {
          height: 180px;
        }

        .item {
          height: 180px;
          transition: all 0.4s;
          overflow: hidden;
          border-radius: 3px;

          img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }

          &:hover {
            border-radius: 10px;
          }
        }

        .owl-controls {
          .owl-dots {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .owl-dot {
              padding: 5px;
              height: 15px;
              width: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all 0.4s;

              span {
                height: 10px;
                min-width: 10px;
                padding: 0;
                margin: 0;
                transition: all 0.4s;
              }

              &.active {
                width: 20px;

                span {
                  background: @color1;
                  height: 15px;
                  min-width: 15px;
                }
              }
            }
          }
        }
      }

      .body-more {
        margin-top: 65px;
        text-align: center;

        .btn {
          width: 400px;
          max-width: 100%;
        }
      }

      .body-product {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .product-gallery {
          width: 38%;

          img {
            width: 100%;
          }

          .lSAction {
            .lSPrev {
              width: 25px;
              height: 25px;
              background: url("../library/lightslider-master/dist/img/prev.svg")
                center;
              background-size: cover;
              margin-top: 0px;
              transform: translateY(-50%);
              left: 1px;
            }

            .lSNext {
              width: 25px;
              height: 25px;
              background: url("../library/lightslider-master/dist/img/prev.svg")
                center;
              background-size: cover;
              margin-top: 0px;
              transform: translateY(-50%) rotate(180deg);
              right: 1px;
            }
          }

          .lSGallery {
            margin-top: 23px !important;

            li {
              border: none;
              opacity: 0.6;
              transition: all 0.3s;

              &.active,
              &:hover {
                opacity: 1;
              }
            }
          }
        }

        .product-info {
          width: 55%;

          h2 {
            font-size: 26px;
            font-weight: 400;
            line-height: 34px;
          }

          .code {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 300;

            strong {
              font-weight: 700;
              color: @color1;
            }
          }

          .description {
            margin-top: 20px;

            p {
              font-size: 14px;
            }
          }

          .features {
            margin-top: 20px;

            > * {
              &:not(:first-child) {
                margin-top: 10px;
              }
            }

            p {
              font-size: 16px;
              font-weight: 300;

              strong {
                font-weight: 700;
                color: #999;
              }
            }

            .select-wrapper-flexbox {
              label {
                font-size: 16px;
                font-weight: 300;
              }

              .select-wrapper {
                position: relative;

                select {
                  cursor: pointer;
                  padding: 10px 100px 10px 10px;
                  border: none;
                  border: 1px solid #bbb;
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  appearance: none;
                  outline: none;
                  display: block;
                  color: #666;
                  width: 100%;
                }

                &:after {
                  cursor: pointer;
                  content: "";
                  height: 10px;
                  width: 10px;
                  background: url("../img/pictures/down-chevron.svg") center;
                  background-size: cover;
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }

          .price {
            margin-top: 20px;
            padding: 23px 20px 20px 20px;
            background: #f1f1e5;

            .price-netto {
              color: @color1;
              font-size: 40px;
              font-weight: 500;
              line-height: 30px;
              display: flex;
              align-items: flex-end;

              span {
                font-weight: 400;
                color: #999;
                font-size: 12px;
                line-height: 15px;
                padding-left: 10px;
              }
            }

            .price-brutto {
              margin-top: 10px;
              font-weight: 400;
              color: #999;
              font-size: 18px;
              line-height: 18px;
              padding-left: 3px;
            }

            .price-availability {
              margin-top: 20px;
              background: #fff;

              .availability-inner {
                display: flex;
                padding: 10px 10px 0;

                p {
                  text-transform: uppercase;
                  color: #000;
                  font-size: 14px;
                  letter-spacing: 0.3px;

                  &:nth-child(2) {
                    margin-left: 10px;
                    font-weight: 700;
                    position: relative;

                    &:after {
                      content: "";
                      height: 10px;
                      width: 10px;
                      border-radius: 50%;
                      position: absolute;
                      right: -20px;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }

                &.red {
                  border-top: 2px solid crimson;

                  p {
                    &:nth-child(2) {
                      &:after {
                        background: crimson;
                      }
                    }
                  }
                }

                &.green {
                  border-top: 2px solid olivedrab;

                  p {
                    &:nth-child(2) {
                      &:after {
                        background: olivedrab;
                      }
                    }
                  }
                }
              }

              .availability-comment {
                padding: 10px;

                p {
                  font-size: 13px;
                }
              }
            }

            .quantity-wrapper {
              margin-top: 20px;

              .wrapper-inner {
                display: flex;

                .input-number {
                  width: 100%;
                  padding: 0 12px;
                  text-align: center;
                  outline: none;
                  background: none;
                  border: none;
                  border-top: 1px solid #999;
                  border-bottom: 1px solid #999;
                }

                .input-number,
                .input-number-decrement,
                .input-number-increment {
                  height: 35px;
                  user-select: none;
                }

                .input-number-decrement,
                .input-number-increment {
                  min-width: 35px;
                  background: none;
                  color: #999;
                  text-align: center;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: background 0.4s;
                  cursor: pointer;
                  border: 1px solid #999;
                  outline: none;

                  &:active {
                    background: @color1;
                    color: #fff;
                  }
                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  appearance: none;
                  margin: 0;
                }

                input[type="number"] {
                  -moz-appearance: textfield;
                }
              }

              .btn {
                margin-top: 20px;
                width: 100%;
              }
            }
          }
        }
      }

      .body-package-wrapper {
        padding: 30px;
        background: #f3f3f3;

        .body-package {
          display: flex;
          justify-content: space-between;

          .package-image {
            width: 22.5%;
          }

          .service-image-wrapper {
            width: 100%;
            padding-top: 100%;
            position: relative;
            border-radius: 7px;
            display: block;
            box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
            transition: all 0.4s;
            background: #fff;

            .service-image {
              text-align: center;
              position: relative;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              overflow: hidden;
              border-radius: 7px;

              img {
                width: 100%;
              }
            }
          }

          .package-info {
            width: 53.83333%;

            h2 {
              font-size: 36px;
              font-weight: 400;
            }

            .price {
              margin-top: 20px;
              color: @color1;
              font-size: 35px;
              font-weight: 500;
              line-height: 30px;
              display: flex;
              align-items: flex-end;

              span {
                font-weight: 400;
                color: #999;
                font-size: 16px;
                line-height: 15px;
                padding-left: 7px;
              }
            }

            .date {
              margin: 20px 0;
              font-size: 16px;
              font-weight: 300;

              strong {
                font-weight: 700;
                color: @color1;
              }
            }

            .btn-more {
              font-size: 13px;
              text-decoration: underline !important;
            }
          }

          .package-gift {
            width: 19%;

            .heading {
              margin-bottom: 20px;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0;
            }
          }
        }

        .collapse-terms {
          margin-top: 30px;
          background: #fff;
          padding: 30px;

          p,
          li {
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.15px;
          }

          ul,
          ol {
            padding-left: 17px;
          }
        }
      }
    }
  }
}

section.kategoria-section-heading {
  position: relative;
  height: 150px;
  overflow: hidden;

  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scaleX(-1);
  }

  .heading-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      font-weight: 300;
      font-size: 24px;
      color: #fff;
    }

    h1 {
      font-size: 48px;
      color: #fff;

      a {
        color: inherit;
        transition: all 0.4s;

        &:hover {
          padding-left: 10px;
        }
      }
    }
  }
}

section.static-page-section-content {
  padding: 70px 0;

  .content-heading {
    text-align: center;

    h2 {
      font-size: 36px;
      font-weight: 400;
    }
  }

  .content-inner {
    margin-top: 40px;

    > * {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    p,
    li {
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.2px;
    }

    ul,
    ol {
      padding-left: 17px;
    }
  }
}

section.kontakt-section-content {
  padding: 70px 0;
  position: relative;

  .service-heading {
    padding-top: 20px;

    h3 {
      font-size: 32px;
    }
  }

  .service-content {
    a {
      font-weight: 700;
      color: @color1;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .content-flexbox {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    .flexbox-heading {
      width: 50%;
      margin-bottom: 20px;

      h2 {
        color: #444;
        font-size: 22px;
        margin-bottom: 10px;
      }
    }

    .flexbox-service {
      width: 33%;

      a {
        font-weight: 700;
        color: @color1;
      }

      h4 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .flexbox-address {
      width: 33%;

      h3 {
        color: @color1;
        font-size: 45px;
        font-weight: 800;
      }

      address {
        margin: 10px 0;
        color: #666;
        font-size: 18px;
        font-weight: 400;

        small {
          margin-top: 20px;
          display: block;
          color: #999;
          margin-bottom: 7px;
        }

        a {
          font-weight: 700;
          color: @color1;
        }
      }

      p {
        color: #666;
      }
    }

    .iframe-wrapper {
      position: relative;
      width: 100%;
      height: 450px;
      margin-top: 40px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}

#return-to-top {
  position: fixed;
  z-index: 333;
  bottom: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d5ab5;
  transition: all 0.6s;
  visibility: hidden;
  transform: rotate(-180deg);
  opacity: 0;

  svg {
    fill: #fff;
    height: 15px;
    width: 15px;
  }

  &:hover {
    background-color: #0e254f;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

section.section-footer {
  padding: 0 0 50px 0;
  background: #525252;
  position: relative;

  .footer-logo-wrapper {
    display: flex;
    justify-content: center;

    .footer-logo {
      transform: translateY(-10px);
      background: @color1;
      padding: 30px 25px;
      box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.24);
      transition: all 0.4s;

      p {
        color: #fff;
        transform: translateY(-5px);

        &:nth-child(1) {
          font-size: 28px;
          font-weight: 700;
          letter-spacing: 5px;
          text-transform: uppercase;
        }

        &:nth-child(2) {
          font-size: 13px;
          font-weight: 300;
          line-height: 15px;
          letter-spacing: 1.8px;
          padding-left: 1px;

          strong {
            font-weight: 700;
          }
        }
      }

      &:hover {
        padding: 30px 35px;
      }
    }
  }

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .flexbox-service {
      margin-top: 50px;

      .service-logo {
        height: 100px;
      }

      ul {
        li {
          margin-top: 10px;

          &.heading {
            margin-top: 0;
            padding-bottom: 10px;
            font-size: 16px;
            color: #e6e6e5;
          }

          a {
            font-size: 12px;
            color: #e6e6e5;
            transition: all 0.3s;

            b {
              font-size: 16px;
            }

            span {
              font-size: 10px;
            }

            &:hover {
              color: @color1;
            }
          }

          p {
            font-size: 12px;
            color: #e6e6e5;

            b {
              font-weight: 400;
              font-size: 14px;
            }

            span {
              font-size: 10px;
            }
          }
        }
      }

      .list-social {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-right: 20px;

          a {
            img {
              height: 25px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .footer-credits {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid #666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    p {
      font-size: 12px;
      color: #e6e6e5;
      font-weight: 300;

      a {
        color: #e6e6e5;
      }
    }

    .footer-payment {
      display: flex;
      align-items: center;

      p {
        margin-right: 15px;
      }

      img {
        margin-right: 10px;
        height: 19px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .alert {
    position: relative;
    margin-top: 50px;
    margin-bottom: 0;
    padding: 50px 0 0 0;
    background: none;
    border: none;
    border-top: 1px solid #666;

    p {
      font-size: 12px;
      color: #e6e6e5;
      font-weight: 300;

      a {
        color: #e6e6e5;
        cursor: pointer;
      }
    }
  }
}
